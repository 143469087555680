$url: '../image/';
$url-fonts: '../fonts/';
$fonts-size: 14px;
$color-blue: #006ed2;
$color-blue-d: #1160a8;
$color-dark: #333333;
$color-orange: #f4a103;
$fontSansR: 'OpenSansRegular', sans-serif;
$fontSansB: 'OpenSansSemiBold', sans-serif;
$fontAvesome: FontAwesome;

@font-face {
    font-family: 'OpenSansRegular';
    src: url( $url-fonts + 'OpenSansRegular/OpenSansRegular.eot');
    src: url($url-fonts + 'OpenSansRegular/OpenSansRegular.eot?#iefix') format('embedded-opentype'), url($url-fonts + 'OpenSansRegular/OpenSansRegular.woff') format('woff'), url($url-fonts + 'OpenSansRegular/OpenSansRegular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: 'OpenSansSemiBold';
    src: url( $url-fonts + 'OpenSansSemiBold/OpenSansSemiBold.eot');
    src: url($url-fonts + 'OpenSansSemiBold/OpenSansSemiBold.eot?#iefix') format('embedded-opentype'), url($url-fonts + 'OpenSansSemiBold/OpenSansSemiBold.woff') format('woff'), url($url-fonts + 'OpenSansSemiBold/OpenSansSemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

body {
    font-family: $fontSansR;
    color: $color-dark;
    font-size: $fonts-size;
    line-height: 24px;
    min-width: 300px;
    a {
        color: $color-dark;
        text-decoration: none;
        font-size: $fonts-size;
        &:hover {
            color: $color-blue-d;
            text-decoration: none;
        }
        &:focus {
            text-decoration: none;
            outline: none;
        }
    }
    h1{
        font-size: 25px;
    }
    h2{
        font-size: 24px;
    }
    h3{
        font-size: 18px;
        font-family: $fontSansB;
    }
    h4{
        font-size: 20px;
    }
    h5{
        font-size: 19px;
    }
    h6{
        font-size: 18px;
    }
    b, strong{
        font-family: $fontSansB;
    }
    .nopadding {
        padding: 0;
    }
    img{
        max-width: 100%;
        height: auto;
    }
    label{
        font-weight: normal;
    }
    button:focus, input:focus, .btn:focus, .btn:active, .btn:active:focus, select:focus{
        outline: none !important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    .fa-close:before{
        content: "\f00d" !important;
    }
    #content{
        min-height: 500px;
    }
    @media (min-width: 1360px) {
        .container {
            width: 1330px;
        }
        .col-left{
            width: 20.7%;
        }
        .col-center{
            width: 58.6%;
            &.col-center-right{
                width: 79.3%;
            }
        }
        .col-right{
            width: 20.7%;
        }
    }
    @media (max-width: 767px){
        .col-right{
            margin-top: 40px;
        }
    }
    .col-left{
        @media (min-width: 768px) and (max-width: 991px){
            margin-bottom: 20px;
        }
    }
    .owl-carousel{
        background: transparent;
        .owl-wrapper-outer{
            border: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }
    input[type=text],
    input[type=time],
    textarea.form-control,
    .input-style{
        border-radius: 0;
        border: 1px solid #e7eaee;
        box-shadow: 0 0 5px 0 rgba(17,96,168,0.1);
        resize: vertical;
    }
    .btn-blue{
        border-radius: 0;
        background: $color-blue-d;
        color: #fff;
        &:hover, &:focus, &:active{
            background: $color-blue;
            color: #fff;
        }
    }
    .heading-padding{
        padding-left: 15px;
        padding-right: 15px;
    }

    header{
        padding-top: 21px;
        .container-header{
            position: relative;
        }
        .logo{
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            min-height: 50px;
            .logo-img{
                flex: 1 0 auto;
                @media (max-width: 490px){
                    text-align: center;
                    margin: 20px 0;
                }
                img{
                    max-height: 80px;
                }
            }
            .form-search{
                flex: 3 0 279px;
                position: relative;
                margin-bottom: 0;
                input[type=text]{
                    width: 100%;
                    border: 2px solid $color-blue-d;
                    border-radius: 30px;
                    height: 35px;
                    padding: 0 70px 0 20px;
                }
                .btn-search{
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: $color-blue-d;
                    border: 2px solid $color-blue-d;
                    border-bottom-right-radius: 30px;
                    border-top-right-radius: 30px;
                    height: 35px;
                    width: 63px;
                    .fa{
                        color: #fff;
                        -webkit-transition: .3s;
                        -moz-transition: .3s;
                        -ms-transition: .3s;
                        -o-transition: .3s;
                        transition: .3s;
                    }
                    &:hover{
                        .fa{
                            font-size: 16px;
                        }
                    }
                }
            }
            @media (min-width: 992px) and (max-width: 1199px){
                align-items: center;
            }
        }
        .right-panel{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: baseline;
            position: static;
            @media (min-width: 992px) and (max-width: 1199px){
                margin-top: 10px;
            }
            @media (min-width: 768px) and (max-width: 991px){
                margin-top: 10px;
                margin-bottom: 10px;
            }
            @media (max-width: 767px){
                margin: 20px 0;
            }
            .phone{
                position: relative;
                user-select: none;
                &:after{
                    content: '\f0d7';
                    color: $color-orange;
                    padding-left: 5px;
                    font: normal normal normal 16px/1 FontAwesome;
                    @media (max-width: 490px){
                        content: '';
                        padding-left: 0;
                    }
                }
                &:hover{
                    cursor: pointer;
                    &:after{
                        color: $color-dark;
                    }
                }
                .phone-all{
                    display: none;
                    position: absolute;
                    background: rgb(255, 255, 255);
                    z-index: 9;
                    padding: 10px;
                    top: 100%;
                    left: -40px;
                    right: -40px;
                    box-shadow: 0 1px 2px 0 rgba(17,96,168,0.1);
                    @media (max-width: 490px){
                        box-shadow: none;
                    }
                    p{
                        margin-bottom: 0;
                    }
                    .note{
                        font-size: 10px;
                        line-height: 15px;
                    }
                    .phone-inner{
                        &>ul{
                            &>li{
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                    }
                    @media (max-width: 490px){
                        display: block;
                        position: relative;
                        padding: 0;
                        left: 0;
                    }
                }
            }
            .flag{
                flex: 3 0 auto;
                margin-bottom: 10px;
                .fa-flag{
                    position: absolute;
                    right: 0;
                    top: 3px;
                    font-size: 18px;
                    padding-left: 5px;
                    color: $color-orange;
                    &:hover{
                        color: $color-dark;
                        cursor: pointer;
                    }
                }
                @media (max-width: 1199px){
                    position: absolute;
                    background: #fff;
                    width: 92px;
                    z-index: 9;
                    text-align: left;
                    height: 20px;
                    overflow: hidden;
                    top: -10px;
                    right: 15px;
                    &.flag-active{
                        height: auto;
                        box-shadow: 0 0 5px 0 rgba(17,96,168,0.1);
                    }
                }
                a{
                    display: inline-block;
                    position: relative;
                    padding-left: 20px;
                    margin-left: 12px;
                    font-size: 11px;
                    color: $color-dark;
                    &:hover{
                        color: $color-blue;
                        text-decoration: none;
                    }
                    &:before{
                        content: '';
                        position: absolute;
                        top: 7px;
                        left: 0;
                        display: block;
                        width: 16px;
                        height: 11px;
                        background: url($url + "flag.png") no-repeat;
                    }
                    &.zh:before{
                        background-position: 0 0;
                    }
                    &.fi:before{
                        background-position: 0 -12px;
                    }
                    &.pt:before{
                        background-position: 0 -24px;
                    }
                    &.es:before{
                        background-position: 0 -36px;
                    }
                    &.sa:before{
                        background-position: 0 -48px;
                    }
                    &.fr:before{
                        background-position: 0 -60px;
                    }
                    &.en:before{
                        background-position: 0 -72px;
                    }
                    &.ru:before{
                        background-position: 0 -84px;
                    }
                    @media (min-width: 1200px) and (max-width: 1359px){
                        margin-left: 7px;
                    }
                }
            }
            .mail, .mode, .call-back{
                float: left;
                flex: 1 0 auto;
                font-size: 15px;
                padding: 0 1px;
                .fa{
                    font-size: 22px;
                    color: $color-orange;
                    @media (max-width: 490px){
                        font-size: 32px;
                        &:hover{
                            cursor: pointer;
                            color: $color-dark;
                        }
                    }
                }
                @media (min-width: 491px) and (max-width: 1199px){
                    font-size: 14px;
                }
                @media (max-width: 490px){
                    position: relative;
                    flex: 1 0 60px;
                }
            }
            .mail:hover{
                cursor: pointer;
                .phone-all{
                    display: block;
                }
            }
            .call-back-but{
                color: $color-orange;
                font-size: 16px;
                @media (min-width: 491px) and (max-width: 1199px){
                    font-size: 14px;
                }
                .fa{
                    padding-right: 5px;
                }
                &:hover{
                    cursor: pointer;
                    span{
                        border-bottom: 1px dashed $color-orange;
                    }
                }
            }
            .mail, .mode{
                display: flex;
                .fa{
                    margin: auto 10px;
                }
            }
            .mail{
                @media (min-width: 1360px){
                    margin-left: 45px;
                }
            }
            .mode{
                @media (min-width: 768px) and (max-width: 991px){
                    padding-left: 50px;
                }
                .fa{
                    @media (max-width: 490px){
                        margin: auto;
                    }
                }
                .info-mod{
                    @media (max-width: 490px){
                        right: -75px;
                        text-align: center;
                    }
                }
            }
            .info-mod{
                &.info-open{
                    width: 75px;
                }
                @media (max-width: 490px){
                    display: none;
                    position: absolute;
                    top: 100%;
                    z-index: 99;
                    background: #fff;
                    left: 0;
                    right: -185px;
                    text-align: right;
                    padding: 10px 20px;
                    min-width: 150px;
                }
            }
        }
        .nav{
            display: flex;
            display: -webkit-box;
            background: $color-blue-d;
            margin-top: 20px;
            margin-bottom: 23px;
            @media (min-width: 992px) and (max-width: 1199px){
                margin-top: 10px;
            }
            &>li{
                position: relative;
                display: block;
                flex: 1 0 120px;
                -webkit-box-flex: 1;
                width: 120px;
                text-align: center;
                -webkit-transition: 1s;
                -moz-transition: 1s;
                -ms-transition: 1s;
                -o-transition: 1s;
                transition: 1s;
                @media (min-width: 768px) and (max-width: 991px){
                    flex: 1 1 auto;
                    width: auto;
                }
                &.dop:hover{
                    &>.sub-category{
                        opacity: 1;
                        height: auto;
                        overflow: visible;
                    }
                }
                :hover, &.menu-active{
                    background: $color-blue;
                    -webkit-transition: 1s;
                    -moz-transition: 1s;
                    -ms-transition: 1s;
                    -o-transition: 1s;
                    transition: 1s;
                }
                &>a{
                    color: #fefefe;
                    padding: 13px 15px;
                    -webkit-transition: .3s;
                    -moz-transition: .3s;
                    -ms-transition: .3s;
                    -o-transition: .3s;
                    transition: .3s;
                    user-select: none;
                    &:focus, &:active{
                        background: transparent;
                    }
                    i{
                        font-size: 18px;
                        top: 1px;
                        position: relative;
                        padding-right: 10px;
                    }
                }
            }
            .sub-category, .sub-category-2{
                position: absolute;
                z-index: 9;
                opacity: 0;
                height: 0;
                width: 100%;
                overflow: hidden;
                text-align: left;
                -webkit-transition: .3s;
                -moz-transition: .3s;
                -ms-transition: .3s;
                -o-transition: .3s;
                transition: .3s;
                &>li{
                    background: #f8f8f8;
                    border-bottom: 1px solid #d0dde8;
                    &:last-child{
                        border-bottom: none;
                    }
                    &>a{
                        display: inline-block;
                        width: 100%;
                        color: $color-dark;
                    }
                    &:hover{
                        background: #e7eaee;
                        &>a{
                            color: $color-blue;
                            background: transparent;
                            text-decoration: none;
                        }
                    }
                }
            }
            .sub-category-2{
                left: 100%;
                top: 0;
                width: auto;
                &>li{
                    background: #e7eaee;
                    &>a{
                        padding: 12px 25px;
                    }
                    min-width: 395px;
                }
            }
            .sub-category{
                &>li{
                    &>a{
                        padding: 12px 30px 12px 70px;
                        @media (min-width: 1200px) and (max-width: 1359px){
                            padding-left: 50px;
                        }
                        @media (min-width: 992px) and (max-width: 1199px){
                            padding-left: 24px;
                        }
                        @media (min-width: 768px) and (max-width: 991px){
                            padding-left: 35px;
                        }
                    }
                    &.dop-level-2:hover{
                        position: relative;
                        &>.sub-category-2{
                            opacity: 1;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    .slider{
        &.bunner{
            margin-bottom: 0;
        }
        margin-bottom: 40px;
        &.top-product-carusel{
            &.owl-carousel{
                .owl-buttons{
                    div{
                        opacity: 1;
                        &.owl-next{
                            right: 15px;
                            top: 46%;
                        }
                        &.owl-prev{
                            left: 15px;
                            top: 46%;
                        }
                        i{
                            font-size: 22px;
                            color: $color-blue-d;
                        }
                    }
                }
                &:hover{
                    .owl-buttons {
                        div {
                            opacity: 1;
                            &.owl-next{
                                right: 15px;
                            }
                            &.owl-prev{
                                left: 15px;
                            }
                            i{
                                font-size: 22px;
                                color: $color-blue-d;
                                &:hover{
                                    color: $color-orange;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.owl-carousel{
            &:hover{
                .owl-buttons {
                    div {
                        opacity: 1;
                        text-shadow: none;
                        &.owl-next{
                            right: 30px;
                        }
                        &.owl-prev{
                            left: 30px;
                        }
                        i{
                            font-size: 22px;
                            color: #fff;
                        }
                    }
                }
            }
            .owl-pagination{
                position: absolute;
                top: auto;
                bottom: 0;
                width: 100%;
                @media (max-width: 767px){
                    display: none;
                }
                .owl-page {
                    margin: 0 3px;
                    span{
                        background: rgba(255, 255, 255, 0.5);
                        box-shadow: none;
                    }
                    &.active{
                        span{
                            background: #fff;
                        }
                    }
                }
            }
            .owl-buttons{
                div{
                    color: #fff;
                    &.owl-next{
                        right: 30px;
                    }
                    &.owl-prev{
                        left: 30px;
                    }
                    i{
                        font-size: 22px;
                        color: #fff;
                    }
                }
            }

        }
        &.top-slider{
            img{
                height: 300px;
                width: 100%;
                object-fit: cover;
                @media (max-width: 767px){
                    height: 185px;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .wrapper-text{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(17, 96, 168, .5);
            padding: 38px;
            @media (max-width: 767px){
                padding: 15px 0;
            }
            .text-slider{
                background: rgba(248, 248, 248, .8);
                width: 390px;
                padding: 30px 25px;
                a{
                    font-family: $fontSansB;
                    font-size: 13px;
                    i{
                        font-size: 14px;
                        padding-left: 5px;
                    }
                }
                @media (min-width: 768px) and (max-width: 991px){
                    &>div{
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-height: 75px;
                    }
                }
                @media (max-width: 767px){
                    width: 100%;
                    &>div{
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-height: 75px;
                    }
                }
            }
            @media (min-width: 768px) and (max-width: 991px){
                padding: 10px;
            }
        }
    }
    .block-shadow{
        border: 1px solid #e7eaee;
        box-shadow: 0 0 5px 0 rgba(17, 96, 168, 0.1);
    }
    .left-menu{
        .nav{
            a.selected, li.selected a{
                color: $color-blue !important;
            }
            &>li{
                &.dop{
                    display: flex;
                    flex-wrap: wrap;
                    .slide-menu{
                        margin: auto;
                        flex: 0 0 30px;
                        text-align: center;
                        color: #cbcbcb;
                        &:hover{
                            color: $color-blue;
                            cursor: pointer;
                        }
                        &.active{
                            color: $color-blue;
                            &:before {
                                content: "\f077";
                            }
                        }
                    }
                    .link-menu{
                        padding-left: 13px;
                        flex: 1 0 100px;
                        &.active {
                            color: $color-blue;
                        }
                        &:hover{
                            background: #fff;
                        }
                    }
                }
                -webkit-transition: 1s;
                -moz-transition: 1s;
                -ms-transition: 1s;
                -o-transition: 1s;
                transition: 1s;
                border-bottom: 1px solid #d0dde8;
                &:last-child{
                    border-bottom: none;
                }
                &>span{
                    -webkit-transition: 1s;
                    -moz-transition: 1s;
                    -ms-transition: 1s;
                    -o-transition: 1s;
                    transition: 1s;
                }
                &>a, &.dop span {
                    display: inline-block;
                    width: 100%;
                    font-family: $fontSansB;
                    font-size: 16px;
                    color: $color-dark;
                    padding: 15px 15px 15px 43px;
                    position: relative;
                    -webkit-transition: 1s;
                    -moz-transition: 1s;
                    -ms-transition: 1s;
                    -o-transition: 1s;
                    transition: 1s;
                    &:hover, &:focus{
                        background: #f8f8f8;
                        cursor: pointer;
                    }
                }
                &.dop{
                    &>span{
                        -webkit-transition: 1s;
                        -moz-transition: 1s;
                        -ms-transition: 1s;
                        -o-transition: 1s;
                        transition: 1s;
                        border-color: #d0dde8;
                        &:before{
                            -webkit-transition: 1s;
                            -moz-transition: 1s;
                            -ms-transition: 1s;
                            -o-transition: 1s;
                            transition: 1s;
                            content: '\f078';
                            font: normal normal normal 14px/1 FontAwesome;
                            color: #cbcbcb;
                            display: block;
                            position: absolute;
                            top: 20px;
                            left: 15px;
                        }
                        &.active{
                            color: $color-blue;
                            background: #f8f8f8;
                            &:before{
                                content: '\f077';
                                color: $color-blue;
                            }
                        }
                    }
                }
                &:hover{
                    &>a, a:before, &>span, &>span:before{
                        color: $color-blue;
                    }
                }
                .sub-category, .sub-category-2{
                    display: none;
                    background: #f8f8f8;
                    width: 100%;
                    &>li{
                        border-bottom: 1px solid #d0dde8;
                        &:last-child{
                            border-bottom: none;
                        }
                        &>a{
                            width: 100%;
                            color: $color-dark;
                            padding: 15px 15px 15px 43px;
                            display: inline-block;
                            position: relative;
                        }
                        &:hover{
                            &>a, a:before{
                                color: $color-blue;
                            }
                        }
                    }
                }
                .sub-category-2{
                    display: block;
                    &>li{
                        &>a{
                            padding: 10px 15px 10px 53px;
                            font-size: 13px;
                            &:before{
                                content: '';
                            }
                        }
                    }
                }
                .dop-level-2{
                    display: flex;
                    flex-wrap: wrap;
                    .slide-menu-2{
                        margin: auto;
                        flex: 0 0 30px;
                        text-align: center;
                        color: #cbcbcb;
                        &:hover{
                            color: $color-blue;
                            cursor: pointer;
                        }
                        &.active{
                            color: $color-blue;
                        }
                    }
                    .link-menu{
                        padding-left: 13px;
                        flex: 1 0 100px;
                        &.active {
                            color: $color-blue;
                        }
                        &:hover{
                            background: #f8f8f8;
                        }
                    }
                    a{
                        &:hover{
                            background: #f8f8f8;
                        }

                    }
                }
            }
        }
        @media (max-width: 767px){
            margin-bottom: 20px;
        }
    }
    .bunner{
        a{
            display: block;
            overflow: hidden;
            position: relative;
            &:before{
                position: absolute;
                top: 0;
                left: -75%;
                z-index: 2;
                display: block;
                content: '';
                width: 50%;
                height: 100%;
                background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
                background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
                -webkit-transform: skewX(-25deg);
                transform: skewX(-25deg);
            }
            &:hover:before{
                -webkit-animation: shine 1.5s;
                animation: shine 1.5s;
            }
        }
        @media (max-width: 767px){
            display: flex;
            flex-wrap: wrap;
            &>div{
                flex: 0 1 350px;
                margin: 10px auto;
            }
        }
    }
    @-webkit-keyframes shine {
        100% {
            left: 125%;
        }
    }
    .label-product{
        position: absolute;
        top: 15px;
        right: 15px;
        font-family: $fontSansB;
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
        border-radius: 30px;
        padding: 1px 20px;
        &.new{
            background: #62bb46;
        }
        &.top{
            background: $color-orange;
        }
        &.discount{
            background: $color-blue;
        }
        &.sale{
            background: #df2530;
        }
    }

    .top-product{
        display: flex;
        flex-wrap: wrap;
        &.top-product-carusel{
            display: block;
            position: static;
            &>div{
                display: block;
            }
        }
        &>div{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 5px;
        }
        .top-product-thumb{
            display: block;
            flex: 1 0 100%;
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: center;
            align-items: center;
            min-height: 158px;
            padding: 5px 15px;
            position: relative;
            -webkit-transition: 1s;
            -moz-transition: 1s;
            -ms-transition: 1s;
            -o-transition: 1s;
            transition: 1s;
            max-width: 360px;
            width: 360px;
            margin-left: auto;
            margin-right: auto;
            @media (max-width: 460px){
                max-width: 100%;
            }
            .img {
                position: relative;
                text-align: center;
                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 1px;
                    background: #e7eaee;
                    top: 25px;
                    bottom: 25px;
                    right: 0;
                    @media (max-width: 1359px){
                        width: 0;
                    }
                }
            }
            &:hover{
                border-color: $color-blue-d;
            }
            .top{
                position: absolute;
                top: 15px;
                right: 15px;
                font-family: $fontSansB;
                font-size: 18px;
                color: #fff;
                text-transform: uppercase;
                background: $color-orange;
                border-radius: 30px;
                padding: 1px 20px;
            }
            .img, .title{
                float: left;
                margin: auto;
                flex: 1 0 150px;
            }
            .title{
                padding: 12px 0 5px 10px;
                font-size: 13px;
                text-align: left;
                margin: 20px 0 20px 10px;
            }
            img{
                max-height: 135px;
                width: auto;
                padding: 5px 0;
            }
            @media (max-width: 1359px){
                text-align: center;
            }
        }
    }
    .top-line{
        &:before{
            content: '';
            display: block;
            height: 1px;
            background: #e7eaee;
            margin: 45px 15px;
        }
    }
    .right-slide, .left-slide{
        font-size: 14px !important;
        color: #cbcbcb;
        background: #f4f4f4;
        border: 1px solid #e7eaee;
        padding: 5px;
        height: 25px;
        width: 25px;
        text-align: center;
        box-shadow: 0 0 5px 0 rgba(17,96,168,0.1);
        &:hover{
            color: $color-blue;
        }
    }
    .top-arrow{
        .owl-controls{
            .owl-buttons{
                position: absolute;
                top: -35px;
                right: -1px;
                width: 100%;
                div{
                    opacity: 1;
                    i{
                        font-size: 14px;
                    }
                }
                .owl-next{
                    right: 0;
                }
                .owl-prev{
                    right: 30px;
                    left: auto;
                }
            }
        }
    }
    .col-right{
        h3{
            padding-right: 70px;
            margin-bottom: 15px;
        }
    }
    .blick-hover{
        position: relative;
        overflow: hidden;
        &:before{
            position: absolute;
            top: 0;
            left: -75%;
            z-index: 2;
            display: block;
            content: '';
            width: 50%;
            height: 100%;
            background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
            background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
            -webkit-transform: skewX(-25deg);
            transform: skewX(-25deg);
        }
        &:hover:before{
            -webkit-animation: shine 1.5s;
            animation: shine 1.5s;
        }
    }
    .blick-wrap{
        &:hover{
            .img-news{
                &:before{
                    -webkit-animation: shine 1.5s;
                    animation: shine 1.5s;
                }
            }
        }
    }
    .news{
        margin-bottom: 55px;
        h3{
            margin-top: 0;
        }
        .wrap-news{
            padding: 15px;
            position: relative;
        }
        img{
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;
            transition: .3s;
            width: 100%;
            height: 120px;
            object-fit: cover;

        }
        .title-news{
            margin: 30px 0 20px 0;
            line-height: 18px;
        }
        .date{
            color: #cecece;
            font-size: 12px;
        }
        .news-item{
            display: block;
            padding-bottom: 10px;
            margin-bottom: 15px;
            border-bottom: 1px solid #e7eaee;
            position: relative;
            &:last-child{
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
    .partners{
        position: relative;
        .wrap-partner{
            .item{
                height: 260px;
                display: flex;
                flex-direction: column;
                .partner-item{
                    flex: 1 0 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        margin: auto;
                    }
                }
            }
        }
        .link-partners{
            position: absolute;
            bottom: 5px;
        }
    }
    .link-partners{
        display: inline-block;
        width: 100%;
        z-index: 99;
        padding: 5px;
        font-size: 13px;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
    .seo-text{
        margin-top: 22px;
        p{
            margin-bottom: 25px;
        }
    }
    .modal-window{
        display: none;
        position: fixed;
        left: 35%;
        right: 35%;
        top: 30%;
        background: #fff;
        z-index: 99;
        padding: 40px 50px 30px 50px;
        border: 1px solid #d2d9e2;
        box-shadow: 0 0 5px 0 rgba(17,96,168,0.3);
        .alert-danger{
            background: transparent;
            border: none;
            font-size: 12px;
            line-height: 15px;
            padding: 0;
        }
        @media (min-width: 768px) and (max-width: 991px){
            left: 27%;
            right: 27%;
        }
        @media (max-width: 767px){
            left: 20px;
            right: 20px;
            min-width: 240px;
        }
    }
    .callback-message{
        margin-top: 20px;
        display: none;
    }
    .modal-close, .modal-close-mob{
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        background: rgba(0, 0, 0, .5);
    }
    .close{
        position: absolute;
        top: 10px;
        right: 13px;
    }
    .facebook{
        position: relative;
        margin-top: 55px;
        .fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe {
            max-width: 100%;
        }
    }
    .product-thumb{
        position: relative;
        display: inline-block;
        margin-bottom: 26px;
        @media (max-width: 767px){
            display: block;
            margin: 0 auto 20px auto;
            width: 320px;
            max-width: 100%;
        }
        .img{
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-transition: 1s;
            -moz-transition: 1s;
            -ms-transition: 1s;
            -o-transition: 1s;
            transition: 1s;
            img{
                max-height: 100%;
                object-fit: cover;
            }
        }
        .title{
            font-size: 13px;
            text-align: center;
            line-height: 20px;
            margin-top: 10px;

        }
        &:hover{
            .img{
                border-color: #1160a8;
            }
        }
    }
    .product-carusel{
        padding: 30px 130px 0 130px;
        position: relative;
        @media (max-width: 767px){
            padding: 30px 30px 0 30px;
        }
        @media (min-width: 1200px) and (max-width: 1359px){
            padding: 30px 30px 0 30px;
        }
        &>div{
            position: static;
        }
    }
    &.popup-body{
        margin-right: 20px;
    }
    .pagination{
        border: none;
        margin: 40px 0;
        &>li{
            & > a, & > span{
                color: $color-dark;
                height: 27px;
                padding: 0 10px;
                font-size: 14px;
                line-height: 27px;
                border: none;
                margin-left: 0;
                &>i{
                    font-size: 15px;
                    color: #9f9f9f;
                    padding: 6px 0;
                }
            }
            & > a:focus, & > span:focus{
                color: $color-blue;
                background: transparent;
            }
            &:last-child > a, &:last-child > span{
                border-right: none;
            }
            & > a:hover, & > a:hover>i{
                background: $color-blue;
                color: #fff;
            }
            &:last-child>a, &:last-child>span {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:first-child>a, &:first-child>span {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        & > .active > span{
            background: $color-blue;
            color: #fff;
            border: none;
        }
    }
    .breadcrumb{
        padding: 0;
        background-color: transparent;
        line-height: 10px;
        &>li{
            &+li:before{
                content: '\f105';
                font: normal normal normal 14px/1 FontAwesome;
            }
            &>a{
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                }
            }
            &>a, &>span{
                font-size: 12px;
                color: $color-blue;
                opacity: .8;
            }
        }
        @media (max-width: 767px){
            display: none;
        }
    }
    .container-top{
        margin-top: 20px;
    }
    .sub-cat-active{
        display: block !important;
    }
    .row-search{
        margin-top: 30px;
        margin-bottom: 30px;
        .checkbox-inline+.checkbox-inline{
            margin-left: 0;
            margin-bottom: 0;
        }
        .button-search{
            @media (max-width: 767px){
                text-align: center;
            }
            @media (min-width: 768px) and (max-width: 1199px){
                .btn-blue{
                    width: 100%;
                }
            }
        }
    }
    .category-item{
        margin: 0 0 50px 0;
        display: block;
        .img-news{
            border: 1px solid #e7eaee;
            box-shadow: 0 0 5px 0 rgba(17,96,168,0.1);
            overflow: hidden;
            max-width: 450px;
            margin: 0 auto;
        }
        img{
            width: 100%;
            height: auto;
        }
        .title-news {
            font-family: "OpenSansSemiBold",sans-serif;
            font-size: 16px;
            margin-top: 10px;
        }
    }
    .case-thumb{
        display: block;
        margin-bottom: 40px;
        .img{
            position: relative;
            overflow: hidden;
            img{
                width: 100%;
                height: auto;
            }
            .details{
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                color: #fff;
                background: rgba(0, 0, 0 ,0.7);
                transition: 1s;
                overflow: hidden;
                height: 55px;
                padding: 5px 0;
                .title{
                    padding: 5px 10px;
                    font-size: 15px;
                    line-height: 20px;
                    min-height: 50px;
                }
                .description{
                    padding: 10px;
                    font-size: 13px;
                }
            }
            @media (max-width: 767px){
                width: 230px;
                margin: 25px auto;
            }

        }
        &:hover{
            .details {
                height: 100%;
                background: rgba(0, 0, 0, .8);
                @media (max-width: 767px){
                    height: 55px;
                    background: rgba(0, 0, 0 ,0.7);
                }
            }

        }
    }
    .news-thumb{
        display: flex;
        margin-bottom: 30px;
        flex-wrap: wrap;
        @media (max-width: 767px){
           text-align: center;
        }
        .news-info{
            flex: 6 0 240px;
            .title{
                font-family: "OpenSansSemiBold",sans-serif;
                font-size: 16px;
                margin-bottom: 20px;
            }
        }

        .img{
            float: left;
            width: 170px;
            flex: 1 0 170px;
            margin-right: 15px;
            position: relative;
            @media (max-width: 767px){
                flex: 1 0 100%;
                display: flex;
            }
            img{
                width: 100%;
                height: 130px;
                object-fit: cover;
                @media (max-width: 767px){
                    width: 300px;
                    height: 250px;
                    margin: auto;
                }
            }
        }
    }
    .row-flex {
        display: flex;
        flex-wrap: wrap;
    }
    .heading{
        font-family: $fontSansB;
        font-size: 22px;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    .description{
        a{
            color: $color-blue;
            &:hover{
                border-bottom: 1px dashed $color-blue;
            }
        }
    }
    #errorDescription{
        h2{
            font-size: 30px;
            span{
                font-family: $fontSansB;
                font-size: 150px;
                color: $color-blue;
                display: block;
                margin: 50px;
            }
        }
        h3{
            margin: 30px 0;
        }
        a{
            font-size: 16px;
            color: $color-blue;
            &:hover{
                border-bottom: 1px dashed $color-blue;
            }
        }
    }
    .btn-orange{
        background: $color-orange;
        font-family: $fontSansB;
        color: #fff;
        border: none;
        font-size: 18px;
        padding: 5px 22px;
        border-radius: 20px;
        -webkit-transition: .5s;
        -moz-transition: .5s ;
        -ms-transition: .5s ;
        -o-transition: .5s ;
        transition: .5s ;
        &:hover{
            background: $color-dark;
            color: #fff;
        }
    }
    .btn-margin{
        margin: 25px 0;
    }
    // product

    .stock{
        font-family: $fontSansB;
        font-size: 16px;
        margin-bottom: 20px;
        i{
            padding-left: 5px;
        }
        &.in-stock{
            color: #82b957;
        }
        &.no-stock{
            color: #ccc;
        }
        &.order{
            color: #df2530;
        }
    }
    .product-name{
        color: $color-blue;
        margin-bottom: 25px;
        font-family: $fontSansB;
        font-size: 22px;
    }
    .contact{
        margin-bottom: 50px;
        .btn-margin{
            margin-top: 60px;
        }
    }
    .contact-product{
        margin-top: 20px;
        b{
            font-family: $fontSansB;
        }
        p{
            margin-bottom: 0;
        }
        .ico-product{
            margin-top: 24px;
            position: relative;
            &:before{
                font-family: $fontAvesome;
                display: block;
                font-size: 20px;
                color: $color-orange;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .addres, .phone, .mail{
            padding-left: 40px;
        }
        .addres{
            &:before{
                content: '\f041';
            }
        }
        .phone{
            &:before{
                content: '\f095';
            }
        }
        .mail{
            &:before{
                content: '\f0e0';
                font-size: 17px;
            }
        }

    }
    .product-img{
        margin-top: 5px;
        .thumbnail{
            border-radius: 0;
            position: relative;
            &:hover{
               border-color: #ccc;
            }
        }
        .product-slider{
            .thumbnail-item{
                border-radius: 0;
                padding: 3px 15px;
                border: none;
                position: relative;
                display: block;
                img{
                    max-width: 100%;
                }
                &:hover{
                    img{
                        border-color: #ccc;
                        cursor: pointer;
                    }
                }
            }
            &.owl-carousel:hover .owl-buttons div {
                opacity: 1;
            }
            .owl-prev{
                top: 40%;
                left: 15px;
            }
            .owl-next{
                top: 40%;
                right: 15px;
            }
            .selected{
                .thumbnail-item{
                    img, &:hover{
                        border-color: $color-blue-d;
                    }
                }

            }
        }
    }
    .delivery-info{
        font-size: 13px;
        h4{
            font-family: $fontSansB;
            font-size: 16px;
            color: $color-blue-d;
            border-top: 1px solid #d0dde8;
            padding: 15px 0 4px 0;
            &:before{
                content: '\f06a';
                font-family: $fontAvesome;
                padding-right: 10px;
            }
            &:first-child{
                border-top: none;
                padding-top: 3px;
                margin-top: 0;
            }
        }
        p{
            line-height: 17px;
            margin-bottom: 13px;
        }
    }
    @media (min-width: 1200px){
        .col-37{
            width: 37%;
        }
        .col-26{
            width: 26%;
            .col-right{
                width: 100%;
                padding: 0;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1199px){
        .col-37{
            width: 50%;
        }
        .col-26{
            width: 100%;
            .col-right{
                width: 100%;
                padding: 0;
            }
        }
    }
    @media (max-width: 767px){
        .col-26{
            .col-right{
                padding: 0;
            }
        }
    }
    .tabs-info{
        margin: 43px 0 20px 0;
        .product-tabs{
            display: flex;
            flex-wrap: wrap;
            &>li{
                flex: 0 0 22.8%;
                text-align: center;
                margin: 0 15px;
                background: #f8f8f8;
                border-bottom: 0;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                &>a{
                    font-family: $fontSansB;
                    font-size: 16px;
                    text-transform: uppercase;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
                &:hover, &.active{
                    background: $color-blue-d;
                    a, a:hover, a:focus{
                        background: transparent;
                        border-color: transparent;
                        color: #fff;
                    }
                }
                @media (max-width: 1360px) {
                    flex: 0 0 22.3%;
                } @media (max-width: 1200px) {
                     flex: 0 0 19.8%;
                }
                @media (max-width: 991px) {
                    margin: 0 5px;
                    flex: 0 0 23.45%;
                }
                @media (max-width: 767px) {
                    flex: 1 0 100%;
                    margin: 0;
                }
            }
        }
        .tab-content{
            padding-top: 15px;
            h5{
                font-family: $fontSansB;
                font-size: 16px;
                margin-top: 30px;
                margin-bottom: 2px;
            }
            font-size: 14px;
            .table-responsive{
                @media (max-width: 767px){
                   padding: 0 10px;
                }
            }
            iframe{
                max-width: 100%;
            }
        }

    }
    #all-description{
        display: none;
    }
    .all-info-button{
        font-family: $fontSansB;
        color: $color-blue-d;
        text-decoration: underline;
        margin-top: 20px;
        display: inline-block;
        &:hover{
            text-decoration: none;
            cursor: pointer;
        }
    }
    .module-title{
        font-family: $fontSansB;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 15px;
    }
    .map{
        margin-top: 20px;
        iframe{
            max-width: 100%;
            @media (max-width: 767px){
                max-height: 300px;
            }
        }
    }
    .heading-about{
        font-family: $fontSansB;
        font-size: 24px;
        color: $color-blue-d;
        margin-bottom: 30px;
    }
    .description-news{
        img{
            display: inline-block;
        }
        p{
            margin-bottom: 20px;
        }
    }
    .description-info{
        padding: 0 15px;
    }
    .form-about-us{
        margin-top: 50px;
        .form-title{
            font-family: $fontSansB;
            font-size: 18px;
            color: $color-blue-d;
            margin-bottom: 25px;
        }
    }
    .zoomPad{
        z-index: 8;
        position: static;
        float: none;
        &>img{
            max-width: 100% !important;
        }
    }
    .zoomWindow, .zoomPup{
        @media (max-width: 767px){
            display: none !important;
        }
    }
    .zoomPad img{
        @media (max-width: 767px){
            opacity: 1 !important;
            margin: 0 auto;
            display: block;
        }
    }
    .partners{
        margin-bottom: 50px;
        .partner-title{
            p {
                margin-bottom: 0;
                font-size: 13px;
            }
        }
        .img{
            margin-bottom: 20px;
            img{
                height: 90px;
            }
        }
    }

    footer{
        color: #f8f8f8;
        background: $color-blue-d;
        padding-top: 13px;
        margin-top: 125px;
        padding-bottom: 20px;
        .nav{
            &>li{
                &>a{
                    display: inline-block;
                    color: #f8f8f8;
                    padding: 3px 0;
                    line-height: 20px;
                    &:hover, &:focus, &:active{
                        background: transparent;
                        text-decoration: underline;
                    }
                }
            }
        }
        a{
            color: #f8f8f8;
            &:hover, &:focus, &:active{
                background: transparent;
                text-decoration: underline;
                color: #f8f8f8;
            }
        }
        p{
            margin-bottom: 3px;
        }
        h3{
            font-size: 16px;
        }

        .row-flex{
            display: flex;
            flex-wrap: wrap;
            .center-border{
                padding-left: 70px;
                &:before, &:after{
                    content: '';
                    position: absolute;
                    width: 1px;
                    background: #f8f8f8;
                    top: 30%;
                    bottom: 25%;
                    opacity: .2;
                }
                &:before{
                    left: 0;
                }
                &:after{
                    right: 0;
                }
                @media (min-width: 768px) and (max-width: 1199px){
                    padding-left: 50px;
                }
            }
            .right-border{
                padding-left: 70px;
            }
        }

        @media (max-width: 767px){
            text-align: center;
            .row-flex>div{
                text-align: center;
                padding: 0 15px !important;
            }
        }
    }
}



